<script>
import InputField from '@/components/general/InputField'
import SelectField from '@/components/general/SelectField'
import LearningCardContent from '@/components/general/LearningCardContent'
import LearningCardList from '@/components/general/LearningCardList'
import EmptyList from '@/components/general/EmptyList'

export default {
  components: { InputField, SelectField, LearningCardContent, LearningCardList, EmptyList },
  name: 'ContentsListTemplate',
  props: {
    title: {
      type: String
    },
    description: {
      type: String
    },
    list: {
      type: Array,
      default: () => []
    },
    availableContentTypes: {
      type: Array,
      default: () => []
    },
    availableSkills: {
      type: Array,
      default: () => []
    },
    actionButtonIcon: {
      type: String
    },
    actionButtonText: {
      type: String
    }
  },
  data () {
    return {
      searchTerm: null,
      contentType: null,
      skillSelected: null,
      emptyList: {
        textOne: '',
        buttonText: '',
        title: ''
      }
    }
  },
  computed: {
    filteredList () {
      return this.list.filter(item => {
        let shouldShow = true
        if (
          (this.contentType && Object.prototype.hasOwnProperty.call(item, 'contentType') && item.contentType.alias !== this.contentType) ||
          (this.contentType && !Object.prototype.hasOwnProperty.call(item, 'contentType') && item.contents.length > 0 && item.contents.every(content => content.contentType !== this.contentType)) ||
          (this.skillSelected && item.skills.findIndex(skill => skill.id === this.skillSelected) === -1) ||
          (this.searchTerm && this.searchTerm.length >= 3 && !item.title.toLowerCase().includes(this.searchTerm.toLowerCase()))
        ) {
          shouldShow = false
        }
        return shouldShow
      })
    }
  },
  methods: {
    debounceUpdateFilters () {
      this.debounceEvent(() => {
        this.$emit('updateList', this.currentMountedFilters)
      }, 450)
    },
    validateWorkspace () {
      if (this.selectedWorkspace.type === 'individual') {
        this.$router.push({ name: this.selectedWorkspace.type + '.discovery.index.search', query: { type: 'contents' } })
      } else if (this.selectedWorkspace.type === 'business') {
        this.$router.push({ name: this.selectedWorkspace.type + '.discovery.index', query: { companyDomain: this.selectedWorkspace.name } })
      }
    },
    showModalAddListContent (params) {
      this.$emit('showModalAddListContent', params)
    },
    defineEmptyList () {
      if (this.$route.name.includes('in.progress')) {
        this.emptyList.title = this.$t('empty.state.title.in.progress:empty.title')
        this.emptyList.textOne = this.$t('empty.state.text.in.progress:empty.text')
        this.emptyList.buttonText = this.$t('empty.state.button:discovery')
      }

      if (this.$route.name.includes('finished')) {
        this.emptyList.title = this.$t('empty.state.title.finished:empty.title')
        this.emptyList.textOne = this.$t('empty.state.text.finished:empty.text')
        this.emptyList.buttonText = this.$t('empty.state.button:discovery')
      }
    }
  },
  created () {
    this.defineEmptyList()
  }
}
</script>
<template>
  <div class="contents-list-template--container">
    <div class="contents-list-template--filter-wrapper">
      <div class="contents-list-template--tip-wrapper" v-if="title || description">
        <h5 class="contents-list-template--tip-title" v-if="title">
          {{ title }}
        </h5>
        <p class="contents-list-template--tip-description" v-if="description">
          {{ description }}
        </p>
      </div>

      <div class="contents-list-template--filter-options">
        <input-field v-model="searchTerm" outlined dense append-icon="mdi-magnify"
          :placeholder="$t('global:search.alt')" background-color="#fff"></input-field>
        <select-field :items="availableContentTypes" class="margin-select-field"
          :placeholder="$t('content.list.template.content.type.select:placeholder')" v-model="contentType" outlined
          clearable small background-color="#fff"></select-field>
        <select-field :items="availableSkills" class="margin-select-field"
          :placeholder="$t('content.list.template.skill.select:placeholder')" v-model="skillSelected" outlined small
          clearable background-color="#fff"></select-field>
        <v-btn v-if="actionButtonText" class="action-button btn transform-unset" dark :color="getPrimaryColor"
          @click="$emit('addNewContent')">
          <v-icon v-if="actionButtonIcon" color="#fff" size="18px" class="mr-2">{{ actionButtonIcon }}</v-icon>
          {{ actionButtonText }}
        </v-btn>
      </div>
    </div>
    <div class="contents-list-template--list">
      <template v-for="item in filteredList">
        <learning-card-content v-if="item.hasOwnProperty('lists')" :key="item.id || item._id" :content="item"
          originWindow=".workspace" :allowUpdate="true" @onRemove="debounceUpdateFilters"
          @showModalAddListContent="params => showModalAddListContent(params)"></learning-card-content>
        <learning-card-list v-if="item.hasOwnProperty('contents')" :key="item.id || item._id" :list="item"
          originWindow=".workspace" :allowUpdate="true" @onRemove="debounceUpdateFilters"></learning-card-list>
      </template>
    </div>
    <empty-list class="empty-list" @btnClick="validateWorkspace()" v-if="(!filteredList || !filteredList.length)"
      :title="emptyList.title" :textOne="emptyList.textOne" :image="'/assets/images/empty-box.png'"
      :buttonText="emptyList.buttonText" :validateRole="false">
      ></empty-list>
  </div>
</template>
<style lang="scss">
.contents-list-template--container {
  margin-top: 40px;

  .contents-list-template--filter-wrapper {
    display: flex;
    flex-direction: column;

    .contents-list-template--tip-wrapper {
      text-align: left;

      h5.contents-list-template--tip-title {
        font-family: $lato;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #242321;
        margin-bottom: 8px;
      }

      p.contents-list-template--tip-description {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #7E7D77;
      }
    }

    .contents-list-template--filter-options {
      display: flex;

      .select-field{
        margin-left: 20px;
      }
    }
  }

  .contents-list-template--list {
    display: grid;
    width: 100%;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(312px, 1fr));

    &>div {
      display: inline-block;
      padding: 0;

      .learning-card--container {
        margin: 0;
        width: 100%;
      }
    }

    .content-card--container {
      width: 100%;
      margin: 0;
    }
  }
}

@media only screen and (max-width: 768px) {

  .contents-list-template--filter-options {

      .select-field{
        margin-left: 0px !important;
      }

      flex-direction: column;
    }

  }
</style>
